<template>
  <div>
  <label>{{local.text}}</label>
  <br>
  <button v-on:click="closeCmp()">Отмена</button>
  <br>
  <button v-on:click="newTemplate()">Сформировать новый</button>
  <br>
  <button v-on:click="oldTemplate()">Скачать уже существующий</button>
  </div>
</template>
<script>
export default {
  props: ['model'],
  name: 'ExportInvestmentRequestAsyncCmp',
  data () {
    return {
      local: this.model
    }
  },
  watch: {
    local () {
      this.model = this.local
    },
    model () {
      this.local = this.model
    }
  },
  methods: {
    closeCmp () {
      this.local.context.$msgbox.close()
    },
    newTemplate () {
      this.local.context.$http({
        method: 'post',
        url: `${this.local.context.$config.api}/registryservice/plugins/execute/ExportAsyncCommand`,
        data: { 'scenarioId': this.local.scenarioId, 'async': 'true' }
      }).then((response) => {
        this.local.context.$msgbox.close()
        this.local.context.$msgbox({
          type: 'info',
          message: 'Выгрузка шаблона поставлена в очередь. По завершении получите уведомление'
        })
      })
    },
    oldTemplate () {
      let address = `${this.local.context.$config.api}/files/${this.local.file_name}`
      let me = this.local.me
      let me2 = this
      this.local.context.$http({
        method: 'get',
        url: address,
        responseType: 'arraybuffer'
      }).then(function (response2) {
        me2.local.context.$msgbox.close()
        me.openExportAttachment(response2.data, me, 'xlsx')
      }).catch((response) => {
        me2.local.context.$msgbox.close()
        me2.local.context.$msgbox({
          type: 'info',
          message: 'При загрузке файла произошла ошибка'
        })
      })
    }
  }
}
</script>

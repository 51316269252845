import BasePlugin from '../BasePlugin'
import ExportInvestmentRequestAsyncCmp from './ExportInvestmentRequestAsyncCmp'

export default class ExportInvestmentRequestAsync extends BasePlugin {
  async execute () {
    let scenarioId = this.context.getModel().id
    let me = this
    let adjustmentRequestCard = this.context.getCard().data

    if (adjustmentRequestCard.hasOwnProperty('attr_4681_')) {
      scenarioId = this.context.getCard().data.attr_4681_
    }

    this.context.$http({
      method: 'get',
      url: `${this.context.$config.api}/registryservice/plugins/execute/CheckExportScenarioExistsQuery?scenarioId=${scenarioId}`
    }).then((response) => {
      if (response.data.exist) {
        const h = this.context.$createElement
        let values = {
          scenarioId: scenarioId,
          file_name: response.data.file_name,
          context: this.context,
          me: this,
          text: `В данном сценарии присутствует файл, собранный ${response.data.last_export_date}. Последнее изменение сценария произошло ${response.data.scenario_last_update}. Хотите загрузить уже существующий файл?`
        }
        this.context.$msgbox({
          title: '',
          message: h('p', { key: Math.random() }, [
            h(ExportInvestmentRequestAsyncCmp, { props: { model: values } })
          ]),
          showCancelButton: false,
          showConfirmButton: false
        }).then(action => {
        })
      } else {
        me.context.$http({
          method: 'post',
          url: `${me.context.$config.api}/registryservice/plugins/execute/ExportAsyncCommand`,
          data: { 'scenarioId': scenarioId, 'async': 'true' }
        }).then((response) => {
          me.context.$msgbox({
            type: 'info',
            message: 'Выгрузка шаблона поставлена в очередь. По завершении получите уведомление'
          })
        })
      }
    })
  }
}
